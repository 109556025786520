import { render, staticRenderFns } from "./payments-card.vue?vue&type=template&id=51ee3cca&"
import script from "./payments-card.vue?vue&type=script&lang=ts&"
export * from "./payments-card.vue?vue&type=script&lang=ts&"
import style0 from "./payments-card.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/dorshoshan/Documents/DEV/Atmos-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('51ee3cca')) {
      api.createRecord('51ee3cca', component.options)
    } else {
      api.reload('51ee3cca', component.options)
    }
    module.hot.accept("./payments-card.vue?vue&type=template&id=51ee3cca&", function () {
      api.rerender('51ee3cca', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/table/mobile-card/payments-card.vue"
export default component.exports