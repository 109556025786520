var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "payments-card" }, [
    _c("div", { staticClass: "payments-card__main" }, [
      _vm.data.order_number
        ? _c("div", {
            staticClass: "payments-card__main_order",
            domProps: {
              textContent: _vm._s(
                _vm.$t("Order") + ": " + _vm.data.order_number
              ),
            },
          })
        : _vm._e(),
      _vm.data.order_number
        ? _c(
            "button",
            {
              staticClass: "payments-card__main_svg",
              on: {
                click: function ($event) {
                  return _vm.data.openOrder(_vm.data.order_number)
                },
              },
            },
            [
              _c("svg-icon", {
                attrs: { name: "ic-arry", width: "8", height: "14" },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("div", {
        staticClass: "payments-card__main_date",
        domProps: {
          textContent: _vm._s(
            _vm.filters.date(_vm.data.date, "DD/MM/YYYY") +
              " " +
              (_vm.data.date === _vm.$t("Now") ? "" : _vm.$t("at")) +
              " " +
              _vm.filters.time(_vm.data.date)
          ),
        },
      }),
      _c("div"),
      _vm.data.type === 1
        ? _c("div", {
            staticClass: "payments-card__main_card",
            domProps: {
              textContent: _vm._s(
                _vm.brands[_vm.data.brand] + "\n" + _vm.data.last_digits
              ),
            },
          })
        : _c("div", {
            staticClass: "payments-card__main_card",
            domProps: {
              textContent: _vm._s(_vm.$t(this.PaymentsToStr[_vm.data.type])),
            },
          }),
      _c("div", {
        staticClass: "payments-card__main_name",
        domProps: { textContent: _vm._s(_vm.data.name) },
      }),
    ]),
    _c("div", { staticClass: "payments-card__footer" }, [
      _vm.data.amount >= 0 && _vm.he
        ? _c("div", {
            staticClass: "payments-card__amount red",
            domProps: { textContent: _vm._s("₪" + _vm.data.amount) },
          })
        : _vm._e(),
      _vm.data.amount < 0 && _vm.he
        ? _c("div", {
            staticClass: "payments-card__amount red",
            domProps: { textContent: _vm._s("₪" + -1 * _vm.data.amount + "-") },
          })
        : _vm._e(),
      _vm.data.amount < 0 && !_vm.he
        ? _c("div", {
            staticClass: "payments-card__amount red",
            domProps: { textContent: _vm._s("-₪" + -1 * _vm.data.amount) },
          })
        : _vm._e(),
      _c(
        "button",
        { staticClass: "btn btn_small btn_green", on: { click: _vm.refund } },
        [
          _c("svg-icon", {
            staticClass: "mie8 ic-white",
            attrs: { name: "ic-repeat", width: "9", height: "10" },
          }),
          _c("span", { domProps: { textContent: _vm._s(_vm.$t("Refund")) } }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }